<template>
  <div class="index">
    <van-tabs v-model="active"  :sticky="false" @change="tabChange" color="#1890ff" title-active-color="#1890ff">
      <van-tab name="1" title="企业流失客户">
        <div class="content">
          <van-list
            v-model="l_loading"
            :finished="l_finished"
            @load="getLossContact"
          >
            <div class="list-item" v-for="(item, index) in lossContactList" :key="index">
              <template v-if="item.contact">
                <a-avatar class="avatar" shape="square" :size="48" icon="user" :src="item.contact.avatar" />
                <div class="info">
                  <div>
                    <label>客户昵称：</label>
                    {{ item.contact.name }}
                  </div>
                  <div>
                    <label>归属员工：</label>
                    {{ item.employee.name }}
                  </div>
                  <div>
                    <label>流失时间：</label>
                    {{ item.created_at }}
                  </div>
                </div>
                <div class="hand">
                  <van-button type="info" round size="mini" @click="pushUserEmployee(item.contact_id, item.employee_id)">查看客户</van-button>
                </div>
              </template>
              <template v-else>
                <a-avatar class="avatar" shape="square" :size="48" icon="user" />
                <div class="info">
                  <div>
                    <label>客户昵称：</label>
                    无用户数据
                  </div>
                  <div>
                    <label>归属员工：</label>
                    {{ item.employee.name }}
                  </div>
                  <div>
                    <label>流失时间：</label>
                    {{ item.created_at }}
                  </div>
                </div>
              </template>
            </div>
            <div v-if="l_finished && lossContactList.length" slot="finished">无更多数据</div>
          </van-list>
          <van-empty
            v-if="l_finished && !lossContactList.length"
            class="custom-image"
            :image="require('../../assets/images/search-no-data.svg')"
            description="暂无数据"
          />
        </div>
      </van-tab>
      <van-tab name="2" title="企业删人提醒">
        <div class="content">
          <van-list
            v-model="d_loading"
            :finished="d_finished"
            @load="getDelContact"
          >
            <div class="list-item" v-for="(dItem, dIndex) in delContactList" :key="dIndex">
              <template v-if="dItem.contact">
                <a-avatar class="avatar" shape="square" :size="48" icon="user" :src="dItem.contact.avatar" />
                <div class="info">
                  <div>
                    <label>客户昵称：</label>
                    {{ dItem.contact.name }}
                  </div>
                  <div>
                    <label>归属员工：</label>
                    {{ dItem.employee.name }}
                  </div>
                  <div>
                    <label>删除时间：</label>
                    {{ dItem.created_at }}
                  </div>
                </div>
                <div class="hand">
                  <van-button type="info" round size="mini" @click="pushUserEmployee(dItem.contact_id, dItem.employee_id)">查看客户</van-button>
                </div>
              </template>
              <template v-else>
                <a-avatar class="avatar" shape="square" :size="48" icon="user" />
                <div class="info">
                  <div>
                    <label>客户昵称：</label>
                    无用户数据
                  </div>
                  <div>
                    <label>归属员工：</label>
                    {{ dItem.employee.name }}
                  </div>
                  <div>
                    <label>删除时间：</label>
                    {{ dItem.created_at }}
                  </div>
                </div>
              </template>
              <!-- <a-avatar class="avatar" shape="square" :size="48" icon="user" :src="dItem.contact.avatar" />
              <div class="info">
                <div>
                  <label>客户昵称：</label>
                  {{ dItem.contact.name }}
                </div>
                <div>
                  <label>归属员工：</label>
                  {{ dItem.employee.name }}
                </div>
                <div>
                  <label>流失时间：</label>
                  {{ dItem.created_at }}
                </div>
              </div>
              <div class="hand">
                <van-button type="info" round size="mini" @click="pushUser(dItem)">查看客户</van-button>
              </div> -->
            </div>
            <div v-if="d_finished && delContactList.length" slot="finished">无更多数据</div>
          </van-list>
          <van-empty
            v-if="d_finished && !delContactList.length"
            class="custom-image"
            :image="require('../../assets/images/search-no-data.svg')"
            description="暂无数据"
          />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { departmentLossContact } from '@/api/lostCustomers'
import { getCookie, pushUserEmployee } from '@/plugins/utils'
export default {
  data() {
    return {
      pushUserEmployee,
      active: '1',
      lossContactList: [],
      l_page: 1,
      l_perPage: 15,
      l_loading: false,
      l_finished: false,

      delContactList: [],
      d_page: 1,
      d_perPage: 15,
      d_loading: false,
      d_finished: false,
    }
  },
  mounted () {
    // this.getLossContact()
    // this.getDelContact()
  },
  methods: {
    tabChange () {},
    pushUser(item) {
      var url = 'https://work.' + location.hostname.split(".")[1] + '.' + location.hostname.split(".")[2] + '/contact?agentId=' + getCookie('workbench_agentId') + '&contactId='+item.contact_id
      window.location.href = url
    },
    getLossContact () {
      this.l_loading = true
      departmentLossContact({
        type: 1,
        page: this.l_page,
        page_size: this.l_perPage
      }).then(res => {
        this.loading = false
        if(res.data.data.length < this.l_perPage){
          this.l_finished = true
          this.l_loading = false
        }else{
          this.l_page++
          this.l_finished = false
          this.l_loading = false
        }
        this.lossContactList = this.lossContactList.concat(res.data.data || [])
      })
    },
    getDelContact () {
      this.d_loading = true
      departmentLossContact({
        type: 2,
        page: this.d_page,
        page_size: this.d_perPage
      }).then(res => {
        this.loading = false
        if(res.data.data.length < this.d_perPage){
          this.d_finished = true
          this.d_loading = false
        }else{
          this.d_page++
          this.d_finished = false
          this.d_loading = false
        }
        this.delContactList = this.delContactList.concat(res.data.data || [])
        console.log(this.delContactList)
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-tabs__line {
  bottom: 4px;
}
/deep/ .van-tabs__nav--line {
  padding-bottom: 8px;
}

/deep/ .van-tab__text {
  margin-bottom: 10px;
  margin-top: 16px;
}

/deep/ .van-tab {
  background: #ffffff;
}

/deep/ .van-tabs__nav {
  // background: #F1f2f5;
}
.index {
  font-size: 14px;
  height: 100%;
  background: #F1f2f5;

  .content {
    padding: 12px;

    .list-item {
      background: #fff;
      margin-bottom: 12px;
      border-radius: 8px;
      padding: 12px;
      display: flex;

      .avatar {
        margin-right: 12px;
      }

      .info {
        flex: 1;
      }

      .hand {
        text-align: right;
        margin-left: 10px;
        display: flex;
        align-items: center;
        // width: 80px;
      }

      /deep/.van-button--mini {
        padding: 0 8px;
      }
    }
  }
}
</style>